<template>
	<div>
		<div class="card" :class="$style.container">
			<div class="text-dark font-size-24 mb-3">
				<strong>Re-Enter Password</strong>
			</div>
			<form @submit.prevent="handleSubmit" autocomplete="off" class="mb-4 ant-form ant-form-horizontal">
				<a-form-item
						:validate-status="(formErrors.has('password') ? 'error' : '')"
						:help="formErrors.first('password')">
					<a-input
							type="password"
							v-model="formFields.password"
							:placeholder="$t('input.password')"
							size="large"/>
				</a-form-item>
				<a-form-item
						:validate-status="(formErrors.has('password_confirmation') ? 'error' : '')"
						:help="formErrors.first('password_confirmation')">
					<a-input
							type="password"
							v-model="formFields.password_confirmation"
							:placeholder="$t('input.confirmPassword')"
							size="large"/>
				</a-form-item>
				<a-button type="primary" htmlType="submit" size="large" class="text-center w-100"
				          :disabled="global.pendingRequests > 0">
					<strong>Set Password</strong>
				</a-button>
			</form>
		</div>
	</div>
</template>
<script>
    import {mapState} from 'vuex'
    import Error from "../../../util/Error"
    import {
        getAuthUser,
        hasAuthUser,
        removeStorage, setStorage,
    } from "./../../../util/Utils"
    import {request} from "./../../../util/Request"

    const DEFAULT_FORM_STATE = {
        email: null,
        password: null,
        password_confirmation: null,
        _method: 'post',
    };

    export default {
        name: 'ResetPassword',
        computed: mapState(['settings', 'global']),
        data: function () {
            return {
                formFields: {...DEFAULT_FORM_STATE},
                formErrors: new Error({}),
                isSubmitted: false
            }
        },
        mounted() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$title = this.$t('title.reenterPassword')
        },
        methods: {
            handleSubmit() {
                if (this.isSubmitted)
                    return false;

                this.isSubmitted = true
                this.formErrors = new Error({})

                request({
                    method: 'POST',
                    url: `/a/auth/reenter/password`,
                    data: {...this.formFields},
                })
                .then((response) => {
                    this.formFields = {...DEFAULT_FORM_STATE}

                    this.newPasswordSet()
                    removeStorage('auth')

                    const {data} = response
                    const {access_token} = data

                    if(access_token) {
                        setStorage(`auth`, JSON.stringify(data))
                        this.intendedRedirect();
                    }
                })
                .catch((errors) => {
                    if (errors.status && errors.status === 422) {
                        this.formErrors = new Error(errors.data.errors)
						return false
                    }

                    this.handleServerError(errors)
                })
                .finally(() => {
                    this.isSubmitted = false
                })
            },
            intendedRedirect() {
                const {redirectTo} = this.$route.query
                if (this.$route.query && (redirectTo && redirectTo !== '' && redirectTo !== 'admin') ) {
                    this.$router.push({path:  _.trimStart(decodeURIComponent(`${this.$route.query.redirectTo}`), 'admin') })
                } else {
                    this.$router.push({name: 'Dashboard'})
                }
            }
        },
    }
</script>
<style lang="scss" module>
	@import "@/views/auth/style.module.scss";
</style>
